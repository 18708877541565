<template>
  <b-card>
    <div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionText: 'garantía(s) seleccionada(s)',
          clearSelectionText: 'Deseleccionar todas',
          selectionInfoClass: 's-box' }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :sort-options="{
          enabled: true,
        }"
        @on-selected-rows-change="selectionChanged"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="receipts.length>0"
          >
            Su búsqueda no coincide
          </h3>
          <b-overlay
            id="overlay-background"
            :show="show"
            rounded="lg"
            variant="bg-light"
            opacity="1.00"
            no-wrap
            no-fade
            class="mt-5"
          />
          <h3
            v-if="!show && receipts.length==0"
          >
            Actualmente no tiene Fondos de Garantia pendientes
          </h3>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'Moneda'">
            {{ parseCurrency(props.row.Moneda) }}
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="primary"
                class="btn-icon"
                block
                :href="props.row.file"
                target="_blank"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrando grupos de
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <div>
      <b-row class="mt-1 text-center">
        <b-col>
          <b-button
            v-b-modal.modal-approve
            variant="primary"
            :disabled="selectedRows.length == 0"
          >
            <b-spinner
              v-if="processing"
              style="margin-bottom: 2px"
              small
            />
            {{ processing ? 'CARGANDO...' : 'Solicito devolución de Fondos de Garantía' }}
          </b-button>
          <p
            class="mt-2"
          >
            <strong>
              En esta sección puedes visualizar los fondos de garantía, de los documentos cancelados por tus clientes, que aún no han sido transferidos a tu cuenta.
              Los fondos de garantía u otras cuentas a favor serán compensadas con cualquier deuda en favor de EFIDE
            </strong>
          </p>
          <!-- confirm modal -->
          <b-modal
            id="modal-approve"
            ref="my-modal"
            centered
            size="sm"
            title="¿Seguro que desea solicitar devolución de estos fondos de garantía?"
            cancel-title="Cancelar"
            ok-title="Aceptar"
            @ok="choiceConfirmed()"
          >
            <b-card-text class="my-1">
              Recuerde que esta acción no es editable
            </b-card-text>
          </b-modal>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BPagination, BFormSelect, BCard, BOverlay, BButton, BRow, BCol, VBModal, BCardText, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BButton,
    BCard,
    BRow,
    BCol,
    BCardText,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      processing: false,
      show: true,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Ruc Cliente',
          field: 'razonSocial',
        },
        {
          label: 'Documento',
          field: 'IdDocumento_Dsc',
        },
        {
          label: 'Mon',
          field: 'Moneda',
        },
        /* {
          label: 'Importe de Pago',
          field: 'ImportePago',
          tdClass: 'text-right',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        {
          label: 'Fecha de Cancelación',
          field: 'cxpFechaCan_Dsc',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
        }, */
        {
          label: 'Garantía disponible',
          field: 'Garantia',
          type: 'number',
          formatFn: this.moneyFormat,
        },
        /* {
          label: 'Días vencidos',
          field: 'DiasVenc',
          type: 'number',
        },
        {
          label: 'Opción',
          field: 'action',
        }, */
      ],
      rows: [],
      receipts: [],
      selectedRows: [],
      isSend: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    await this.getFunds()
  },
  methods: {
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
    },
    dateFormat(value) {
      return value.split('-').reverse().join('/')
    },
    async getFunds() {
      try {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const { efideData: { clientId } } = userData

        const { data: { guaranteeFunds } } = await useJwt.getOutstandingGuaranteeFunds({ clientId })
        const values = guaranteeFunds
        for (let i = 0; i < values.length; i += 1) {
          values[i].cxpFechaCan_Dsc = values[i].cxpFechaCan_Dsc.toString().replace(/\s/g, '').trim().split('/')
            .reverse()
            .join('-')
          values[i].file = `https://online.efide.pe/FilesEF/Liquidaciones/${values[i].IdLote}.pdf`
        }
        this.rows = values
        this.receipts = this.rows
        this.show = false
      } catch (error) {
        console.log(error)
        this.show = false
      }
    },
    selectionChanged(params) {
      this.selectedRows = []
      this.selectedRows = params.selectedRows
    },
    choiceConfirmed() {
      this.processing = true
      let StrCreditoId = []
      this.selectedRows.forEach(row => {
        StrCreditoId.push(row.creditoID)
      })
      StrCreditoId = StrCreditoId.join('|')
      const userD = JSON.parse(localStorage.getItem('userData'))
      useJwt.postReturnRequest({
        Usuario: 'UserAPI',
        OPCION: 1,
        IdSocio: userD.efideData.clientId,
        StrCreditoId,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Éxito',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Se ha enviado tu solicitud con éxito.',
            },
          }, {
            timeout: 8000,
          })
        }).then(() => {
          this.setRows()
          this.processing = false
        })
        .catch(error => {
          console.log('errores', error)
          this.processing = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error && error.Result ? error.Result : 'Error al solicitar a Efide',
            },
          },
          {
            timeout: 8000,
          })
        })
    },
    parseCurrency(currency) {
      switch (currency) {
        case 'US$':
          return 'USD'
        case 'S/':
          return 'PEN'
        default:
          return '-'
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
